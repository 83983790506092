import React, { useState } from "react";

const Member = ({ name, title, description }: { name: string, title: string, description: Array<string> }) => {
   const [isOpen, setIsOpen] = useState(false);
    return (
      <>
      <figure onClick={() => setIsOpen(true)} className="team openModal" data-target="bb-modal" aria-hidden="true" tabIndex={0}>
               <figcaption>
                  <h5>{name}</h5>
                  <span>{title}</span>
               </figcaption>
            </figure>
        <article className={`bio-modal ${isOpen ? 'open' : ''}`} id="me-modal">
         <div className="bio-wrap">
            <span style={{cursor: 'pointer'}} onClick={() => setIsOpen(false)} className="modalClose" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.88 23.88">
                  <path fill="#ed4e53"
                     d="m0,1.25L1.25,0l22.63,22.63-1.25,1.25L0,1.25Zm0,21.38L22.63,0l1.25,1.25L1.25,23.88l-1.25-1.25Z">
                  </path>
               </svg></span>
            <div className="col col-rt">
               <h5>{name}</h5>
               <span>{title}</span>
               <ul>
                  {description.map((item, index) => (
                     <li key={index}>{item}</li>
                  ))}
               </ul>
            </div>
            </div>
        </article> 
        </>
    )
}

export default Member;