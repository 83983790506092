import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import './style/style.css'
import './style/styles.css'
import './style/css2.css'
import './style/font-awesome.css'
import './style/font-awesome2.css'
import './style/font-awesome3.css'

import TeamMember from './component/team/member'
import Form from './component/form'

const polylineStyle = {
  fill: 'none',
  stroke: '#ef6845',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: '5px',
}

function App() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash === '#form') {
        setIsFormOpen(true)
        setTimeout(() => {
          const element = document.querySelector('#form')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }, 600)
      }
    }, 300)
  }, [])

  return (
    <>
      <div id='preloader' style={{ display: 'none' }}>
        <div id='status' style={{ display: 'none' }}></div>
      </div>

      <header
        className={
          (menuOpen ? 'open' : '') + (window.innerWidth < 768 ? ' down' : '')
        }>
        <svg
          id='header_logo'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 250 59.28'>
          <path
            className='hlogo-3'
            fill='#2f56a3'
            d='m42.48,0H0v27.35h42.48c7.55,0,13.67-6.12,13.67-13.67S50.03,0,42.48,0h0Z'></path>
          <path
            className='hlogo-5'
            fill='#3b4368'
            d='m42.48,31.93H0s0,27.35,0,27.35h42.48c7.55,0,13.67-6.12,13.67-13.67,0-7.55-6.12-13.67-13.67-13.67h0Z'></path>
          <path
            className='hlogo-2'
            fill='#ed4e53'
            d='m85.19,59.28h28.81v-27.34h-56.16c0,15.1,12.24,27.34,27.34,27.34h0Z'></path>
          <path
            className='hlogo-1'
            fill='#ef6845'
            d='m85.19,0c-15.1,0-27.34,12.24-27.34,27.34h0s56.16,0,56.16,0V0h-28.81Z'></path>
          <path
            className='hlogo-4'
            fill='#231f20'
            d='m137.51,27.94V2.51h9.59c1.83,0,3.35.29,4.55.87,1.2.58,2.11,1.37,2.71,2.37.6,1,.9,2.13.9,3.39,0,1.03-.2,1.92-.59,2.65-.39.73-.92,1.32-1.58,1.77-.66.45-1.4.77-2.21.97v.25c.89.05,1.74.32,2.56.83.82.5,1.49,1.21,2.02,2.12.53.92.79,2.02.79,3.31s-.31,2.49-.94,3.53c-.63,1.04-1.58,1.86-2.86,2.47-1.28.6-2.9.91-4.85.91h-10.09Zm4.33-14.55h4.77c.82,0,1.56-.15,2.21-.46s1.17-.74,1.56-1.3c.38-.56.58-1.23.58-2,0-1.01-.35-1.85-1.06-2.51-.71-.66-1.77-.99-3.18-.99h-4.87v7.26Zm0,10.91h5.17c1.73,0,2.98-.34,3.74-1.01.76-.67,1.14-1.52,1.14-2.55,0-.78-.19-1.48-.58-2.11-.38-.63-.93-1.13-1.64-1.5-.71-.37-1.55-.55-2.52-.55h-5.3v7.71Z'></path>
          <path
            className='hlogo-4'
            d='m171.93,19.97v-11.1h4.22v19.07h-4.11v-3.35h-.2c-.43,1.04-1.13,1.9-2.1,2.58-.97.68-2.18,1.02-3.62,1.02-1.23,0-2.33-.28-3.28-.83-.95-.55-1.7-1.37-2.23-2.43-.54-1.07-.81-2.38-.81-3.92v-12.13h4.22v11.53c0,1.24.34,2.23,1.02,2.96s1.57,1.1,2.68,1.1c.67,0,1.33-.17,1.98-.5.65-.33,1.19-.83,1.61-1.5.42-.67.63-1.5.62-2.5Z'></path>
          <path
            className='hlogo-4'
            d='m182.6,6.08c-.69,0-1.28-.23-1.77-.69-.49-.46-.74-1.02-.74-1.67s.25-1.2.74-1.66c.49-.46,1.08-.69,1.77-.69s1.29.23,1.78.69.73,1.02.73,1.66-.24,1.21-.73,1.67c-.49.46-1.08.69-1.78.69Zm-2.12,21.87V8.87h4.22v19.07h-4.22Z'></path>
          <path
            className='hlogo-4'
            d='m193.24,2.51v25.43h-4.22V2.51h4.22Z'></path>
          <path
            className='hlogo-4'
            d='m204.66,28.29c-1.51,0-2.87-.39-4.05-1.16-1.19-.77-2.13-1.9-2.81-3.36-.69-1.47-1.03-3.24-1.03-5.32s.35-3.88,1.04-5.35c.7-1.46,1.64-2.57,2.84-3.33,1.2-.76,2.54-1.14,4.04-1.14,1.14,0,2.07.19,2.79.57.72.38,1.29.83,1.7,1.36.41.53.74,1.01.97,1.45h.2V2.51h4.21v25.43h-4.13v-2.99h-.27c-.23.45-.56.93-.99,1.45-.43.52-1.01.96-1.73,1.33s-1.65.56-2.76.56Zm1.11-3.49c.99,0,1.84-.27,2.53-.81.69-.54,1.22-1.28,1.58-2.24.36-.96.55-2.07.55-3.35s-.18-2.37-.53-3.31c-.36-.94-.88-1.67-1.58-2.19s-1.54-.78-2.55-.78-1.91.27-2.61.81-1.23,1.29-1.58,2.24c-.35.95-.53,2.03-.53,3.23s.18,2.32.53,3.28.88,1.72,1.58,2.28c.7.56,1.57.84,2.6.84Z'></path>
          <path
            className='hlogo-4'
            d='m227.49,28.33c-1.9,0-3.54-.4-4.92-1.21-1.37-.81-2.43-1.94-3.17-3.41-.74-1.47-1.11-3.2-1.11-5.18s.37-3.68,1.12-5.17c.74-1.49,1.79-2.65,3.12-3.48,1.34-.83,2.91-1.25,4.71-1.25,1.14,0,2.23.19,3.27.55,1.03.37,1.95.94,2.76,1.72.8.78,1.44,1.77,1.9,2.99.46,1.21.7,2.67.7,4.37v1.35h-15.46v-2.94h11.39c0-.9-.19-1.71-.56-2.41-.37-.7-.9-1.26-1.57-1.66-.68-.4-1.46-.61-2.37-.61-.97,0-1.81.23-2.53.7-.72.46-1.28,1.07-1.68,1.82-.4.75-.6,1.57-.61,2.46v2.47c0,1.17.21,2.16.63,2.99.42.83,1.01,1.46,1.77,1.9.76.44,1.64.66,2.66.66.67,0,1.28-.09,1.83-.28.55-.19,1.02-.48,1.42-.86s.7-.85.92-1.42l3.95.53c-.27,1.08-.78,2.03-1.51,2.83-.73.8-1.66,1.43-2.79,1.87-1.13.45-2.42.67-3.87.67Z'></path>
          <path
            className='hlogo-4'
            d='m239.36,27.94V8.87h4.08v3.13h.2c.35-1.08.95-1.91,1.81-2.51.86-.6,1.84-.9,2.94-.9.24,0,.52.01.83.03s.57.05.77.09v3.92c-.17-.06-.46-.11-.86-.17-.4-.05-.79-.08-1.16-.08-.84,0-1.6.18-2.26.53-.66.36-1.18.85-1.56,1.48-.38.63-.57,1.36-.57,2.17v11.37h-4.22Z'></path>
          <path
            className='hlogo-4'
            d='m159.05,40.57h-4.37c-.14-.79-.4-1.48-.77-2.09-.37-.6-.83-1.12-1.38-1.54-.55-.42-1.17-.74-1.87-.96s-1.44-.32-2.22-.32c-1.42,0-2.67.35-3.77,1.06-1.1.71-1.96,1.74-2.59,3.1-.63,1.36-.94,3.02-.94,4.99s.32,3.66.95,5.02c.63,1.36,1.5,2.39,2.6,3.08,1.1.7,2.34,1.04,3.74,1.04.78,0,1.51-.1,2.2-.31.69-.21,1.31-.51,1.86-.92.55-.41,1.02-.91,1.4-1.51.38-.6.65-1.28.79-2.05l4.37.02c-.19,1.23-.58,2.37-1.16,3.42-.58,1.06-1.33,1.98-2.26,2.76s-2,1.4-3.22,1.84c-1.22.44-2.57.66-4.04.66-2.22,0-4.2-.52-5.94-1.56-1.74-1.04-3.11-2.53-4.12-4.49-1-1.95-1.5-4.29-1.5-7.02s.5-5.07,1.51-7.02c1.01-1.95,2.38-3.44,4.13-4.48,1.75-1.04,3.72-1.56,5.92-1.56,1.39,0,2.69.2,3.89.59,1.2.39,2.27.97,3.22,1.73.95.76,1.73,1.68,2.34,2.77.61,1.09,1.02,2.33,1.23,3.73Z'></path>
          <path
            className='hlogo-4'
            d='m164.87,35.66c-.69,0-1.28-.23-1.77-.69-.49-.46-.74-1.02-.74-1.67s.25-1.2.74-1.66c.49-.46,1.08-.69,1.77-.69s1.29.23,1.78.69.73,1.02.73,1.66-.24,1.21-.73,1.67c-.49.46-1.08.69-1.78.69Zm-2.12,21.87v-19.07h4.22v19.07h-4.22Z'></path>
          <path
            className='hlogo-4'
            d='m171.3,57.53v-19.07h4.08v3.13h.2c.35-1.08.95-1.91,1.81-2.51s1.84-.9,2.94-.9c.24,0,.52.01.83.03s.57.05.77.09v3.92c-.17-.06-.46-.11-.86-.17-.4-.05-.79-.08-1.16-.08-.84,0-1.6.18-2.26.53-.66.36-1.18.85-1.56,1.48-.38.63-.57,1.36-.57,2.17v11.37h-4.22Z'></path>
          <path
            className='hlogo-4'
            d='m192.05,57.91c-1.88,0-3.49-.42-4.84-1.26-1.35-.84-2.39-2-3.12-3.48-.73-1.48-1.09-3.18-1.09-5.1s.37-3.66,1.11-5.14c.74-1.48,1.79-2.64,3.14-3.48,1.35-.84,2.94-1.25,4.77-1.25,1.51,0,2.86.28,4.04.83s2.12,1.33,2.82,2.34c.71,1.01,1.12,2.18,1.24,3.52h-4.06c-.18-.91-.61-1.68-1.28-2.3s-1.56-.94-2.68-.94c-.97,0-1.81.25-2.53.76-.72.51-1.28,1.24-1.68,2.19-.4.95-.6,2.08-.6,3.38s.2,2.48.6,3.44c.4.96.96,1.7,1.68,2.22.72.52,1.57.78,2.55.78.68,0,1.29-.13,1.83-.38s1-.62,1.37-1.11c.37-.48.62-1.07.76-1.76h4.06c-.12,1.31-.53,2.47-1.2,3.48-.68,1.02-1.6,1.81-2.76,2.38s-2.53.86-4.09.86Z'></path>
          <path
            className='hlogo-4'
            d='m207.71,32.1v25.43h-4.22v-25.43h4.22Z'></path>
          <path
            className='hlogo-4'
            d='m220.4,57.91c-1.9,0-3.54-.4-4.92-1.21-1.37-.81-2.43-1.94-3.17-3.41-.74-1.47-1.11-3.2-1.11-5.18s.37-3.68,1.12-5.17c.74-1.49,1.79-2.65,3.12-3.48,1.34-.83,2.91-1.25,4.71-1.25,1.14,0,2.23.19,3.27.55,1.03.37,1.95.94,2.76,1.72.8.78,1.44,1.77,1.9,2.99.46,1.21.7,2.67.7,4.37v1.35h-15.46v-2.94h11.39c0-.9-.19-1.71-.56-2.41-.37-.7-.9-1.26-1.57-1.66-.68-.4-1.46-.61-2.37-.61-.97,0-1.81.23-2.53.7-.72.46-1.28,1.07-1.68,1.82-.4.75-.6,1.57-.61,2.46v2.47c0,1.17.21,2.16.63,2.99.42.83,1.01,1.46,1.77,1.9.76.44,1.64.66,2.66.66.67,0,1.28-.09,1.83-.28.55-.19,1.02-.48,1.42-.86s.7-.85.92-1.42l3.95.53c-.27,1.08-.78,2.03-1.51,2.83-.73.8-1.66,1.43-2.79,1.87-1.13.45-2.42.67-3.87.67Z'></path>
        </svg>

        <nav className={menuOpen ? 'main open' : 'main'}>
          <ul>
            <li>
              <a
                href='/#about-us'
                className='scroll'
                onClick={() => setMenuOpen(false)}>
                About Us
              </a>
            </li>
            <li>
              <a
                href='/#loan-products'
                className='scroll'
                onClick={() => setMenuOpen(false)}>
                Loan Products
              </a>
            </li>
            <li>
              <a
                href='/#circle-benefits'
                className='scroll'
                onClick={() => setMenuOpen(false)}>
                Circle Benefits
              </a>
            </li>
            <li>
              <a
                href='/#partnerships'
                className='scroll'
                onClick={() => setMenuOpen(false)}>
                Partnerships
              </a>
            </li>
            <li>
              <a
                href='/#leadership'
                className='scroll'
                onClick={() => setMenuOpen(false)}>
                Leadership
              </a>
            </li>
            <li>
              <a
                href='/#contact'
                className='scroll'
                onClick={() => setMenuOpen(false)}>
                Investment
              </a>
            </li>
            <li>
              <a
                href='/#contact'
                className='scroll button'
                onClick={() => setMenuOpen(false)}>
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <button
          id='navBtn'
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
          className={menuOpen ? 'visible-md active' : 'visible-md'}>
          <span className='bars'></span>
        </button>
      </header>
      <section id='hero'>
        <figure>
          <img src='./assets/bc-hero.png' alt='' />
          <figcaption>
            <h1>A Better Finance Network, Built for the Builders</h1>
            <a
              className='button scroll'
              href='/#form'
              onClick={() => setIsFormOpen(true)}>
              Apply For Loan Now
            </a>
          </figcaption>
        </figure>
      </section>
      {isFormOpen && (
        <section id='form' style={{ paddingTop: '60px' }}>
          <Form />
        </section>
      )}
      <section id='about-us'>
        <article className='contain contain-sm'>
          <h4>About Us</h4>
          <p>
            Builder Circle is a Midwest-based lending company that provides
            independent home builders, rehabbers, developers, and real estate
            investors with a better, more practical, and more efficient approach
            to the loan underwriting and construction draw processes.
          </p>
          <p>
            As experienced real estate lenders with deep roots in the home
            building industry, we have an intimate understanding of the
            challenges that our borrowers often face and how we can become a
            tremendous value-add to their growing businesses by eliminating
            common inefficiencies and providing fast, reliable capital.{' '}
          </p>
          <p>
            At Builder Circle we believe in establishing lasting relationships
            with our borrowers, and we have the capital, experience, and
            resources necessary to help them thrive.{' '}
          </p>
        </article>
      </section>
      <section id='loan-products'>
        <div className='contain col-wrap'>
          <div className='col col-lt'>
            <h2>
              Loan <br /> Products
            </h2>
          </div>
          <div className='col col-rt'>
            <p>
              Builder Circle offers a wide variety of loan products to
              businesses across the country, specializing in the following:
            </p>
          </div>
        </div>
        <article className='loans'>
          <figure className='loan'>
            <img src='./assets/development-loans.jpg' alt='' />
            <figcaption>
              <h3>
                Development <br />
                Loans
              </h3>
              <div className='hidden-copy'>
                <ul>
                  <li>
                    Financing for both land acquisition and development costs
                  </li>
                  <li>Up to 90% loan-to-cost</li>
                  <li>Up to 75% loan-to-value (as completed)</li>
                </ul>
              </div>
            </figcaption>
          </figure>
          <figure className='loan'>
            <img src='./assets/rehab-loans.jpg' alt='' />
            <figcaption>
              <h3>
                Rehab <br />
                Loans
              </h3>
              <div className='hidden-copy'>
                <ul>
                  <li>Financing for both land acquisition and rehab costs</li>
                  <li>Up to 90% loan-to-cost</li>
                  <li>Up to 75% loan-to-value (as repaired)</li>
                </ul>
              </div>
            </figcaption>
          </figure>
          <figure className='loan'>
            <img src='./assets/bridge-loans.jpg' alt='' />
            <figcaption>
              <h3>
                Acquisition and <br />
                Bridge Loans
              </h3>
              <div className='hidden-copy'>
                <ul>
                  <li>Financing for land acquisition</li>
                  <li>
                    Refinancing / cash-out financing for land already under
                    ownership
                  </li>
                  <li>Up to 77.5% loan-to-value (current)</li>
                </ul>
              </div>
            </figcaption>
          </figure>
          <figure className='loan'>
            <img src='./assets/rental-loans.jpg' alt='' />
            <figcaption>
              <h3>
                Rental <br />
                Loans
              </h3>
              <div className='hidden-copy'>
                <ul>
                  <li>Financing for single family rental properties</li>
                  <li>Financing for multifamily rental properties</li>
                  <li>
                    Max loan amounts determined based upon property value and
                    DSCR
                  </li>
                </ul>
              </div>
            </figcaption>
          </figure>
        </article>
      </section>
      <section id='circle-benefits'>
        <article className='col-wrap'>
          <figure className='col col-lt'>
            <img src='./assets/circle-benefits.jpg' alt='' />
          </figure>
          <div className='col col-rt'>
            <h4>Circle Benefits</h4>
            <h2>With Builder Circle, the perks go far beyond financing.</h2>
            <p>
              Builder Circle borrowers have access to exclusive partner
              benefits, including cash management services, supplier and trade
              discount programs, and access to our ever-expanding network of
              industry partners.
            </p>
            <a
              className='button scroll blue'
              href='/#form'
              onClick={() => {
                setIsFormOpen(true)
              }}>
              Apply For Loan Now
            </a>
          </div>
        </article>
        <article className='perks'>
          <img src='./assets/perk-circle.jpg' alt='' />
          <div className='perk-grid'>
            <figure className='perk'>
              <svg
                id='broad_reach'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 75.08 75.08'>
                <polyline
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  points='37.54 37.54 37.54 72.58 48.43 61.7'></polyline>
                <line
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  x1='37.54'
                  y1='72.58'
                  x2='26.65'
                  y2='61.7'></line>
                <polyline
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  points='37.54 37.54 72.58 37.54 61.7 26.65'></polyline>
                <line
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  x1='72.58'
                  y1='37.54'
                  x2='61.7'
                  y2='48.43'></line>
                <polyline
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  points='37.54 37.54 2.5 37.54 13.39 48.43'></polyline>
                <line
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  x1='2.5'
                  y1='37.54'
                  x2='13.39'
                  y2='26.65'></line>
                <polyline
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  points='37.54 37.54 37.54 2.5 26.65 13.39'></polyline>
                <line
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  x1='37.54'
                  y1='2.5'
                  x2='48.43'
                  y2='13.39'></line>
              </svg>
              <h3>Broad Reach</h3>
              <p>
                Based in the Midwest, we have experience lending across the
                country to companies of all sizes and configurations.
              </p>
            </figure>
            <figure className='perk'>
              <svg
                id='focused'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 74.65 74.59'>
                <path
                  fill='#ef6845'
                  d='m62.38,21.48l-1.57,1.57c3.06,4.71,4.87,10.45,4.87,16.5,0,16.89-13.75,30.64-30.64,30.64S4.4,56.44,4.4,39.55s13.75-30.64,30.64-30.64c6.05,0,11.71,1.81,16.5,4.87l1.57-1.57v-2.67c-5.26-3.22-11.47-5.03-18.07-5.03C15.71,4.51,0,20.22,0,39.55s15.71,35.04,35.04,35.04,35.04-15.71,35.04-35.04c0-6.6-1.81-12.81-5.03-18.07h-2.67Z'></path>
                <path
                  fill='#ef6845'
                  d='m46.51,18.81c-3.38-1.89-7.31-2.99-11.47-2.99-13.04,0-23.73,10.61-23.73,23.73s10.69,23.73,23.73,23.73,23.73-10.61,23.73-23.73c0-4.16-1.1-8.09-2.99-11.47l-3.22,3.22c1.18,2.51,1.81,5.26,1.81,8.25,0,10.69-8.64,19.33-19.33,19.33s-19.33-8.72-19.33-19.33,8.72-19.33,19.33-19.33c2.99,0,5.74.63,8.25,1.81l3.22-3.22Z'></path>
                <path
                  fill='#ef6845'
                  d='m33.71,31.61l4.16-4.16c-.86-.24-1.81-.31-2.83-.31-6.84,0-12.41,5.58-12.41,12.41s5.58,12.41,12.41,12.41,12.41-5.58,12.41-12.41c0-.94-.08-1.89-.31-2.83l-4.16,4.16c-.63,3.85-3.93,6.76-7.94,6.76-4.48,0-8.09-3.61-8.09-8.09,0-4.01,2.99-7.31,6.76-7.94h0Z'></path>
                <path
                  fill='#ef6845'
                  d='m74.48,8.2c-.24-.63-.86-1.02-1.49-1.02h-5.58V1.6c0-.63-.39-1.26-1.02-1.49-.63-.24-1.34-.08-1.81.39l-6.68,6.68c-.31.31-.47.71-.47,1.18v5.74l-23.96,23.89c-.86.86-.86,2.28,0,3.06.39.39,1.02.63,1.57.63s1.1-.24,1.57-.63l23.96-23.96h5.74c.47,0,.86-.16,1.18-.47l6.68-6.68c.47-.39.63-1.1.31-1.73h0Z'></path>
              </svg>
              <h3>Focused</h3>
              <p>
                We have simplified the borrowing process for many of our
                borrower partners that previously relied on friends and family
                capital or burdensome web-based lenders.{' '}
              </p>
            </figure>
            <figure className='perk'>
              <svg
                id='invested'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 82.16 63.17'>
                <path
                  fill='#ef6845'
                  stroke='#ef6845'
                  strokeMiterlimit={10}
                  strokeWidth='2.16px'
                  d='m55.57,45.35c1.81.45,3.42.09,4.91-.9,3.06-2.02,3.69-6.63,1.22-9.12-5.85-5.89-11.72-11.77-17.6-17.64-2.65-2.64-5.8-2.73-8.64-.29-2.5,2.16-4.98,4.34-7.49,6.48-.93.79-1.84,1.64-2.89,2.25-1.54.9-3.39.37-4.45-1.09-1.08-1.48-.92-3,.47-4.36,1.93-1.89,3.86-3.78,5.79-5.67,3.07-3.01,6.15-6.02,9.23-9.03,1.57-1.54,3.41-2.43,5.63-2.43,1.08,0,2.15,0,3.23-.04,2.32-.09,4.31.65,6.05,2.19,3.01,2.67,6.06,5.31,9.07,7.98,1.55,1.38,3.34,2.09,5.4,2.1,4.75.03,9.51.04,14.26.06.12,0,.25,0,.37-.03.62-.14.96-.54.94-1.17-.01-.63-.35-1.03-.99-1.12-.27-.04-.55-.02-.82-.02-4.53-.02-9.06-.05-13.59-.06-1.69,0-3.08-.66-4.32-1.78-2.45-2.22-4.93-4.39-7.42-6.57-1.43-1.25-2.8-2.63-4.66-3.23-3.45-1.11-6.96-.93-10.45-.2-2.08.44-3.61,1.87-5.09,3.31-4.77,4.68-9.53,9.36-14.31,14.03-2.64,2.59-2.42,6.43.51,8.71,2.26,1.76,5.41,1.57,7.79-.48,3.09-2.66,6.16-5.34,9.25-8.01,1.98-1.7,3.69-1.64,5.54.22,5.74,5.75,11.46,11.54,17.25,17.25,1.24,1.23,1.53,3.75.03,5.29-1.55,1.61-3.52,1.59-5.15-.03-1.22-1.22-6.87-6.97-8.09-8.18-.71-.7-1.36-.76-1.9-.23-.53.53-.45,1.22.22,1.9.95.96,6.35,6.42,7.3,7.39,1.52,1.56,1.26,4.09-.53,5.3-1.39.94-2.97.82-4.25-.38-1.02-.96-6.41-6.51-7.4-7.5-.65-.65-1.32-.72-1.85-.21-.54.52-.47,1.22.2,1.9.65.66,5.75,5.82,6.39,6.5,1.07,1.15,1.24,3,.4,4.24-1.25,1.84-3.46,2.14-5.13.67-.79-.69-5.92-6-6.68-6.73-.52-.49-1.23-.49-1.69-.06-.49.47-.48,1.17.04,1.73.24.26,4.93,5.01,5.19,5.25.71.65,1.14,1.46,1.23,2.42.13,1.32-.32,2.41-1.39,3.21-1.02.76-2.12.73-3.18.14-.78-.44-1.52-.97-2.19-1.56-2.72-2.4-5.46-4.78-8.08-7.28-2.35-2.24-5-3.29-8.27-3.18-4.1.13-8.21.03-12.31.03-.2,0-.4-.01-.6.02-.62.11-.98.49-.99,1.13,0,.64.34,1.03.95,1.18.24.06.5.03.75.03,4.43,0,8.86.04,13.29,0,1.77-.01,3.28.52,4.59,1.7,2.6,2.34,5.21,4.69,7.84,7,1.11.97,2.2,1.99,3.45,2.75,3.7,2.28,7.85.27,8.5-4.03.03-.21.09-.42.13-.63,4.5.43,6.26-.85,7.57-5.51,4.28.84,7.28-2.12,7.44-5.61Z'></path>
                <path
                  fill='#ef6845'
                  stroke='#ef6845'
                  strokeMiterlimit={10}
                  strokeWidth='2.16px'
                  d='m55.57,45.35c-.16,3.49-3.16,6.45-7.44,5.61-1.31,4.66-3.07,5.94-7.57,5.51-.04.21-.1.42-.13.63-.65,4.31-4.79,6.31-8.5,4.03-1.24-.77-2.34-1.78-3.45-2.75-2.63-2.31-5.23-4.65-7.84-7-1.31-1.18-2.82-1.71-4.59-1.7-4.43.03-8.86,0-13.29,0-.25,0-.51.02-.75-.03-.6-.15-.96-.54-.95-1.18,0-.64.37-1.02.99-1.13.19-.03.4-.02.6-.02,4.1,0,8.21.1,12.31-.03,3.26-.11,5.92.95,8.27,3.18,2.62,2.5,5.37,4.88,8.08,7.28.67.59,1.41,1.12,2.19,1.56,1.05.6,2.16.63,3.18-.14,1.07-.8,1.52-1.89,1.39-3.21-.09-.96-.52-1.77-1.23-2.42-.26-.24-4.95-4.99-5.19-5.25-.53-.56-.54-1.26-.04-1.73.46-.44,1.17-.43,1.69.06.76.72,5.9,6.03,6.68,6.73,1.66,1.47,3.88,1.16,5.13-.67.84-1.23.68-3.09-.4-4.24-.63-.68-5.73-5.84-6.39-6.5-.67-.68-.74-1.38-.2-1.9.53-.51,1.19-.45,1.85.21.99.99,6.38,6.54,7.4,7.5,1.28,1.21,2.87,1.32,4.25.38,1.79-1.21,2.05-3.75.53-5.3-.94-.97-6.34-6.43-7.3-7.39-.68-.68-.76-1.37-.22-1.9.54-.54,1.19-.47,1.9.23,1.23,1.21,6.87,6.97,8.09,8.18,1.63,1.62,3.6,1.64,5.15.03,1.49-1.54,1.21-4.06-.03-5.29-5.79-5.71-11.5-11.49-17.25-17.25-1.85-1.85-3.56-1.92-5.54-.22-3.09,2.66-6.16,5.34-9.25,8.01-2.38,2.05-5.52,2.24-7.79.48-2.93-2.28-3.15-6.12-.51-8.71,4.77-4.67,9.54-9.35,14.31-14.03,1.48-1.45,3.01-2.88,5.09-3.31,3.48-.73,7-.91,10.45.2,1.85.6,3.23,1.97,4.66,3.23,2.48,2.18,4.97,4.35,7.42,6.57,1.24,1.12,2.63,1.78,4.32,1.78,4.53.01,9.06.04,13.59.06.28,0,.55-.02.82.02.64.09.98.49.99,1.12.01.63-.32,1.03-.94,1.17-.12.03-.25.03-.37.03-4.75-.02-9.51-.03-14.26-.06-2.06-.01-3.85-.72-5.4-2.1-3.01-2.67-6.06-5.31-9.07-7.98-1.73-1.54-3.73-2.28-6.05-2.19-1.07.04-2.15.04-3.23.04-2.23,0-4.07.89-5.63,2.43-3.07,3.01-6.15,6.02-9.23,9.03-1.93,1.89-3.86,3.78-5.79,5.67-1.39,1.36-1.55,2.88-.47,4.36,1.06,1.46,2.91,1.99,4.45,1.09,1.04-.61,1.96-1.46,2.89-2.25,2.51-2.15,4.99-4.33,7.49-6.48,2.83-2.44,5.99-2.35,8.64.29,5.88,5.87,11.74,11.75,17.6,17.64,2.48,2.49,1.84,7.1-1.22,9.12-1.49.98-3.09,1.35-4.91.9Z'></path>
              </svg>
              <h3>Invested</h3>
              <p>
                Our borrower partners work directly with our leadership team and
                decision makers. We’re with you, every step of the way.{' '}
              </p>
            </figure>
            <figure className='perk'>
              <svg
                id='proven'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 77.15 59.03'>
                <polyline
                  fill='none'
                  stroke='#ef6845'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='5'
                  points='2.5 0 2.5 56.53 77.15 56.53'></polyline>
                <rect
                  fill='#ef6845'
                  x='11.95'
                  y='33.88'
                  width='15'
                  height='22.65'></rect>
                <rect
                  fill='#ef6845'
                  x='32.32'
                  y='20.48'
                  width='15'
                  height='36.05'></rect>
                <rect
                  fill='#ef6845'
                  x='52.7'
                  y='.22'
                  width='15'
                  height='56.3'></rect>
              </svg>
              <h3>Proven</h3>
              <p>
                We have successfully assisted numerous borrower partners in
                scaling their businesses year over year by providing the capital
                needed for project growth.
              </p>
            </figure>
          </div>
        </article>
      </section>
      <section id='partnerships'>
        <article className='contain col-wrap'>
          <div className='col col-lt'>
            <h4>PARTNERSHIPS</h4>
            <h2>At Builder Circle we create profitable collaborations.</h2>
            <p>
              In addition to offering our core lending products, Builder Circle
              welcomes the opportunity to partner with emerging real estate and
              PropTech companies across the country.{' '}
            </p>
          </div>
          <div className='col col-rt'>
            <ul>
              <li>
                We have identified creative ways to assist emerging companies
                focused on short-term and vacation rentals, second home
                ownership, modular housing, and other specialty areas.
              </li>
              <li>
                Our seasoned capital markets team is available to assist our
                partners in identifying institutional capital in the form of
                common equity, preferred equity, senior debt, and mezzanine
                debt.
              </li>
            </ul>
            <p>
              Please{' '}
              <a href='/#contact' className='scroll'>
                Contact Us
              </a>{' '}
              to discuss our unique partnership offerings.
            </p>
          </div>
        </article>
      </section>
      <section id='leadership'>
        <article className='contain'>
          <div className='col-wrap'>
            <div className='col col-lt'>
              <h4>Leadership</h4>
              <h2>
                For Builders, <br />
                by Builders
              </h2>
            </div>
            <div className='col col-rt'>
              <p>
                With over 50 years of capital markets experience, 20 years of
                home building experience, and more than a decade of real estate
                and construction lending experience, the Builder Circle
                leadership team are experts in both the residential construction
                and finance industries.
              </p>
            </div>
          </div>
          <div className='team-grid'>
            <TeamMember
              name='Bruce Bloomingdale'
              title='Co-Founder & CEO'
              description={[
                'Co-owner of a multigenerational, family owned custom and spec home building company',
                'Former structured finance partner at Top 20 global law firms',
                'Recognized by Tier One Legal 500 and Band One Chambers',
              ]}
            />
            <TeamMember
              name='Bruce Margulis'
              title='Co-Founder & President'
              description={[
                'Founded Innova Capital, a real estate lending company',
                'Formerly practiced as a law partner focused on financial services, real estate, and M&A',
                'Recognized by Super Lawyers and Best Lawyers in America',
              ]}
            />
            <TeamMember
              name='Dwayne Myers'
              title='Regional Sales Manager'
              description={[
                'Specializes in business development & relationship management',
                'Experienced sales executive focused on growth and the borrower experience',
                'Seasoned financial services professional',
              ]}
            />
            <TeamMember
              name="Jennifer O'Brien"
              title='Head of Processing'
              description={[]}
            />
            <TeamMember
              name='Bryan Thomas'
              title='Chief Financial Officer'
              description={[
                'Experienced real estate developer including several successful coworking spaces',
                'Former controller at Pulte Group, the 3rd largest home builder in the US',
                'CPA and licensed real estate agent',
              ]}
            />
            <TeamMember
              name='Lisa Guastella'
              title='Assistant Controller'
              description={[
                'Experienced accountant for multigenerational home builders',
                'Focus areas include customer service and construction draw management',
              ]}
            />
            <TeamMember
              name='Doug Rahaim'
              title='Chief Revenue Officer'
              description={[
                'Experienced real estate broker/advisor having negotiated hundreds of residential deals in the past few years',
                'Previously at a boutique investment brokerage, and founding agent at DOBI one of the highest producing teams in Metro Detroit',
                'Multiple recognitions by Hour Magazine All Star, Faces of Detroit, and Best of Zillow Awards',
              ]}
            />
          </div>
        </article>
      </section>

      <section id='contact'>
        <article className='contain col-wrap'>
          <div className='col col-lt'>
            <svg
              id='bc-mark'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 201.35 183'>
              <path
                fill='#ed4e53'
                d='m22.19,87.45C24.31,45.99,58.7,12.92,100.67,12.92s76.36,33.08,78.48,74.53h12.93C189.96,38.8,149.85,0,100.67,0S11.39,38.8,9.27,87.45h12.93Z'></path>
              <path
                fill='#ec4e52'
                d='m179.15,95.55c-2.11,41.46-36.51,74.53-78.48,74.53S24.31,137,22.19,95.55h-12.93c2.12,48.65,42.23,87.45,91.4,87.45s89.28-38.8,91.4-87.45h-12.93Z'></path>
              <path
                fill='#f0e9d7'
                d='m75.03,39.15H0v48.3h75.03c13.34,0,24.15-10.81,24.15-24.15s-10.81-24.15-24.15-24.15'></path>
              <path
                fill='#f0e9d7'
                d='m75.03,95.55H0v48.29h75.03c13.34,0,24.15-10.81,24.15-24.15s-10.81-24.15-24.15-24.15'></path>
              <path
                fill='#f0e9d7'
                d='m150.46,143.84h50.88v-48.29h-99.18c0,26.67,21.62,48.29,48.29,48.29'></path>
              <path
                fill='#f0e9d7'
                d='m150.46,39.15c-26.67,0-48.29,21.62-48.29,48.29h0s99.18,0,99.18,0v-48.3h-50.88Z'></path>
            </svg>
          </div>
          <div className='col col-rt'>
            <h2>Interested in becoming a member of The Circle? </h2>

            <p>Contact us today to get the ball rolling.</p>
            <ul>
              <li>
                <span>E.</span>{' '}
                <a href='mailto:lending@builder-circle.com'>
                  lending@builder-circle.com
                </a>
              </li>
            </ul>
            <p className='border-top'>
              Investment opportunities within Builder Circle are also available.
              Please contact us for more information.{' '}
            </p>
          </div>
        </article>
      </section>
      <footer className='col-wrap'>
        <div className='col col-lt'>
          <p>Copyright 2024, Builder Circle. All Rights Reserved.</p>
        </div>
        <div className='col col-rt'></div>
      </footer>
    </>
  )
}

export default App
