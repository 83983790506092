import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { FormItem } from 'react-hook-form-antd'
import {
  Form,
  Slider,
  Button,
  Typography,
  Select,
  Input,
  DatePicker,
  Divider,
  InputNumber,
} from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = z
  .object({
    loanAmount: z.number().min(65000).max(3000000),
    propertyType: z.string({ required_error: 'Property Type is required' }),
    purchasePrice: z.number({ required_error: 'Purchase Price is required' }),
    propertyAddress: z.string({
      required_error: 'Property Address is required',
    }),
    totalLiquidAssets: z.number({
      required_error: 'Total Liquid Assets is required',
    }),
    firstName: z.string({ required_error: 'First Name is required' }),
    lastName: z.string({ required_error: 'Last Name is required' }),
    dateOfBirth: z.string({ required_error: 'Date of Birth is required' }),
    cellPhone: z.number({ required_error: 'Cell Phone is required' }),
    email: z.string().email({ message: 'Email is required' }),
    accountPassword: z.string({
      required_error: 'Account Password is required',
    }),
    confirmAccountPassword: z.string({
      required_error: 'Confirm Account Password is required',
    }),
    howDidYouHearAboutUs: z.string({
      required_error: 'How did you hear about us is required',
    }),
    brokerOrReferralName: z.string({
      required_error: 'Broker or Referral Name is required',
    }),
  })
  .refine(data => data.accountPassword === data.confirmAccountPassword, {
    message: "Passwords don't match",
    path: ['confirmAccountPassword'],
  })

type FormValues = {
  loanAmount: number
  propertyType: string
  purchasePrice: number
  propertyAddress: string
  propertyCity: string
  propertyState: string
  propertyZip: string
  propertyCounty: string
  entityType: string
  entityName: string
  totalLiquidAssets: number
  firstName: string
  lastName: string
  dateOfBirth: string
  cellPhone: string
  email: string
  howDidYouHearAboutUs: string
  brokerOrReferralName: string
  accountPassword: string
  confirmAccountPassword: string
}

const LoanForm = () => {
  const [form] = Form.useForm()

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      loanAmount: 500000,
      dateOfBirth: '',
    },
    resolver: zodResolver(schema),
  })

  const onSubmit = async (data: any) => {
    const applicationRequest = {
      CustomerID: '272',
      IsBorrowerApplication: 'True',
      LoanAmount: data.loanAmount,
      PropertyType: data.propertyType,
      PurchasePrice: data.purchasePrice,
      TotalLiquidAssets: data.totalLiquidAssets,
      PropertyAddress: data.propertyAddress,
      PropertyCity: data.propertyCity,
      PropertyState: data.propertyState,
      PropertyZip: data.propertyZip,
      PropertyCounty: data.propertyCounty,
      EntityType: data.entityType,
      EntityName: data.entityName,
      BorrowerFirstName: data.firstName,
      BorrowerLastName: data.lastName,
      BorrowerDOB: data.dateOfBirth,
      BorrowerCellPhone: data.cellPhone,
      BorrowerEmail: data.email,
      ReferralSource: data.howDidYouHearAboutUs,
      ReferredBy: data.brokerOrReferralName,
    }
    const url =
      'https://builder-circle.liquidlogics.com/QuickApp/InteractiveAppAjax?campaignid=&user='
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(applicationRequest),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      console.log('Success:', result)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBac40j8nhuGIjSiI7ppBK_MxlbjGd6CkU',
    libraries: ['places'],
  })

  const [address, setAddress] = useState('')
  const searchBoxRef: any = useRef(null)

  const onPlacesChanged = useCallback(() => {
    const places = searchBoxRef?.current?.getPlaces()
    if (places && places.length > 0) {
      const c = places[0].address_components
      setAddress(places[0].formatted_address)
      setValue(
        'propertyAddress',
        `${
          c.find((comp: any) => comp.types.includes('street_number'))?.long_name
        } ${c.find((comp: any) => comp.types.includes('route'))?.long_name}`,
      )
      setValue(
        'propertyCity',
        c.find((comp: any) => comp.types.includes('locality'))?.long_name,
      )
      setValue(
        'propertyState',
        c.find((comp: any) =>
          comp.types.includes('administrative_area_level_1'),
        )?.long_name,
      )
      setValue(
        'propertyZip',
        c.find((comp: any) => comp.types.includes('postal_code'))?.long_name,
      )
      setValue(
        'propertyCounty',
        c.find((comp: any) =>
          comp.types.includes('administrative_area_level_2'),
        )?.long_name,
      )
    }
    void trigger('propertyAddress')
  }, [])

  return (
    <div style={{ padding: '40px' }}>
      <Form
        name='loanForm'
        form={form}
        onFinish={handleSubmit(onSubmit)}
        layout='vertical'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Title style={{ color: '#2f56a3' }} level={4}>
            Loan Amount:{' '}
            {Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(watch('loanAmount'))}
          </Typography.Title>
        </div>
        <Form.Item rules={[{ required: true }]}>
          <Controller
            name='loanAmount'
            control={control}
            render={({ field }) => (
              <Slider
                {...field}
                min={65000}
                max={3000000}
                step={10000}
                tooltip={{
                  open: false,
                }}
                marks={{
                  500000: '500k',
                  1000000: '1M',
                  1500000: '1.5M',
                  2000000: '2M',
                  2500000: '2.5M',
                  3000000: '3M',
                }}
                onChange={value => field.onChange(value)}
                value={watch('loanAmount')}
              />
            )}
          />
        </Form.Item>
        <Typography.Title
          style={{ marginTop: '60px', color: '#2f56a3' }}
          level={5}>
          Property Details
        </Typography.Title>
        <Divider />

        <FormItem control={control} name='propertyType' label='Property Type'>
          <Select placeholder='Select Property Type'>
            <Select.Option value='single-family'>Single Family</Select.Option>
            <Select.Option value='townhome'>Townhome</Select.Option>
            <Select.Option value='lot'>Lot</Select.Option>
            <Select.Option value='2-unit'>2 Unit</Select.Option>
            <Select.Option value='3-unit'>3 Unit</Select.Option>
            <Select.Option value='4-unit'>4 Unit</Select.Option>
            <Select.Option value='other'>Other</Select.Option>
          </Select>
        </FormItem>

        <FormItem control={control} name='purchasePrice' label='Purchase Price'>
          <InputNumber
            style={{ width: '100%' }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value =>
              value?.replace(/\$\s?|(,*)/g, '') as unknown as number
            }
            placeholder='Enter Purchase Price'
          />
        </FormItem>

        <FormItem
          control={control}
          name='propertyAddress'
          label='Property Address'>
          {isLoaded && (
            <StandaloneSearchBox
              onLoad={ref => (searchBoxRef.current = ref)}
              onPlacesChanged={onPlacesChanged}>
              <Input
                placeholder='Enter Property Address'
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </StandaloneSearchBox>
          )}
        </FormItem>

        <Typography.Title
          style={{ marginTop: '60px', color: '#2f56a3' }}
          level={5}>
          Entity Details
        </Typography.Title>
        <Divider />

        <FormItem control={control} name='entityType' label='Entity Type'>
          <Select>
            <Select.Option value='1'>LLC</Select.Option>
            <Select.Option value='2'>LP</Select.Option>
            <Select.Option value='3'>GP</Select.Option>
            <Select.Option value='4'>Corporation</Select.Option>
            <Select.Option value='5'>Other</Select.Option>
            <Select.Option value='6'>Trust</Select.Option>
            <Select.Option value='7'>S-Corporation</Select.Option>
            <Select.Option value='8'>C-Corporation</Select.Option>
            <Select.Option value='9'>Individual</Select.Option>
            <Select.Option value='10'>Limited Partnership</Select.Option>
            <Select.Option value='11'>
              Limited Liability Partnership
            </Select.Option>
            <Select.Option value='12'>IRA</Select.Option>
            <Select.Option value='13'>Not For-Profit Corporation</Select.Option>
            <Select.Option value='14'>Limited Liability Company</Select.Option>
            <Select.Option value='15'>General Partnbership</Select.Option>
            <Select.Option value='16'>Solo 401k</Select.Option>
            <Select.Option value='17'>IRA LLC</Select.Option>
          </Select>
        </FormItem>

        <FormItem control={control} name='entityName' label='Entity Name'>
          <Input placeholder='Enter Entity Name' />
        </FormItem>

        <FormItem
          control={control}
          extra='Builder Circle requires a minimum of $50,000'
          name='totalLiquidAssets'
          label='Total Liquid Assets'>
          <InputNumber
            style={{ width: '100%' }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value =>
              value?.replace(/\$\s?|(,*)/g, '') as unknown as number
            }
            placeholder='Enter Total Liquid Assets'
          />
        </FormItem>

        <Typography.Title
          style={{ marginTop: '60px', color: '#2f56a3' }}
          level={5}>
          Borrower Details
        </Typography.Title>
        <Divider />

        <FormItem control={control} name='firstName' label='First Name'>
          <Input placeholder='Enter First Name' />
        </FormItem>

        <FormItem control={control} name='lastName' label='Last Name'>
          <Input placeholder='Enter Last Name' />
        </FormItem>

        <Form.Item name='dateOfBirth' label='Date of Birth'>
          <DatePicker
            onChange={(a, b) => {
              if (b && typeof b === 'string') {
                setValue('dateOfBirth', b)
              }
            }}
            multiple={false}
            type='date'
            format='MM/DD/YYYY'
          />
        </Form.Item>

        <FormItem control={control} name='cellPhone' label='Cell Phone'>
          <InputNumber
            style={{ width: '100%' }}
            formatter={value => {
              if (!value) return ''
              const numbers = value.toString().replace(/\D/g, '')
              const match = numbers.match(/^(\d{3})(\d{3})(\d{4})$/)
              if (match) {
                return `${match[1]}-${match[2]}-${match[3]}`
              }
              return numbers
            }}
            parser={value => `${value}`.replace(/-/g, '')}
            placeholder='Enter Cell Phone'
          />
        </FormItem>

        <FormItem control={control} name='email' label='Email'>
          <Input placeholder='Enter Email' />
        </FormItem>

        <Typography.Title
          style={{ marginTop: '60px', color: '#2f56a3' }}
          level={5}>
          Loan Portal Account
        </Typography.Title>
        <Divider />

        <FormItem
          control={control}
          name='accountPassword'
          label='Account Password'>
          <Input.Password placeholder='Account Password' />
        </FormItem>

        <FormItem
          control={control}
          name='confirmAccountPassword'
          label='Confirm Account Password'>
          <Input.Password placeholder='Confirm Account Password' />
        </FormItem>

        <Typography.Title
          style={{ marginTop: '60px', color: '#2f56a3' }}
          level={5}>
          Other
        </Typography.Title>
        <Divider />
        <FormItem
          control={control}
          name='howDidYouHearAboutUs'
          label='How did you hear about us?'>
          <Select>
            <Select.Option value='Broker'>Broker</Select.Option>
            <Select.Option value='DirectMail'>DirectMail</Select.Option>
            <Select.Option value='TradeShow'>TradeShow</Select.Option>
            <Select.Option value='FriendRelative'>FriendRelative</Select.Option>
            <Select.Option value='EmailMarketing'>EmailMarketing</Select.Option>
            <Select.Option value='BorrwerReferral'>
              BorrwerReferral
            </Select.Option>
            <Select.Option value='InvestorReferral'>
              InvestorReferral
            </Select.Option>
            <Select.Option value='EmployeeReferral'>
              EmployeeReferral
            </Select.Option>
            <Select.Option value='PersonalReferral'>
              PersonalReferral
            </Select.Option>
            <Select.Option value='RepeatBorrower'>RepeatBorrower</Select.Option>
            <Select.Option value='Meetup'>Meetup</Select.Option>
            <Select.Option value='LinkedIn'>LinkedIn</Select.Option>
            <Select.Option value='Facebook'>Facebook</Select.Option>
            <Select.Option value='BiggerPockets'>BiggerPockets</Select.Option>
            <Select.Option value='GOOGLE'>GOOGLE</Select.Option>
            <Select.Option value='Instagram'>Instagram</Select.Option>
            <Select.Option value='TextBlast'>TextBlast</Select.Option>
            <Select.Option value='HappyHour'>HappyHour</Select.Option>
            <Select.Option value='Events'>Events</Select.Option>
            <Select.Option value='Realtor'>Realtor</Select.Option>
          </Select>
        </FormItem>

        <FormItem
          control={control}
          name='brokerOrReferralName'
          label='Broker or Referral Name'>
          <Input placeholder='Enter Broker or Referral Name' />
        </FormItem>
        <Divider />
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            loading={formState.isSubmitting}
            disabled={!formState.isValid && !formState.isSubmitting}>
            Submit Application
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoanForm
